<template>
  <page child class="mine">
    <v-header></v-header>
    <template v-if="userInfo.titleResourceUrl">
      <img :src="userInfo.titleResourceUrl" @click="changeTitle" width="100%" class="user-title-img">
    </template>
    <img v-else src="https://cdn.001ppt.cn/h5/assets/images/userinfo.png" width="100%" @click="changeTitle"
         class="user-title-img">

    <div class="userinfo-group">
      <div class="user-card">
        <div class="avatar-cell">
          <!--<img class="avatar" :src="user.photoResourceUrl">-->
          <div @click="$router.push('/editInfo')">
            <avatar class="avatar" :src="userInfo.photoResourceUrl"></avatar>
          </div>

          <template v-if="userVip.vipIdentify == 1">
            <img class="vip-img" v-if="getVipIcon(userVip.vipRightId)" :src="getVipIcon(userVip.vipRightId)" alt="">
          </template>

        </div>
        <div class="usernamegroup">
          <div>
            <div class="user-group">
              <span class="username">{{ userInfo.username }}</span>
              <img class="level" :src="`https://cdn.001ppt.cn/h5/static/level/level${userInfo.userLevel}.svg`">
            </div>
            <div class="desc">
              <span>{{ clipCity(userInfo.city) }}</span>
              <span class="line">|</span>
              <span>{{ userInfo.profession || '未知' }}</span>
            </div>
          </div>

          <!--<van-icon name="arrow" size="18"></van-icon>-->
          <i class="iconfont icon-edit editIcon" @click="$router.push('/editInfo')"></i>

        </div>
      </div>

      <div class="box-group"></div>
      <div class="user-count">
        <div>
          <span class="number">{{ numWanFilter(userInfo.popularity) }} <img
            style="height: 16px;width: 16px;transform: translateY(1px)" src="https://cdn.001ppt.cn/h5/img/fire.svg"
            alt=""></span>
          <span class="title">人气</span>
        </div>
        <div @click="showFans(1)">
          <span class="number">{{ numWanFilter(userInfo.fansAmount) }}</span>
          <span class="title">粉丝</span>
        </div>
        <div @click="showFans(2)">
          <span class="number">{{ numWanFilter(userInfo.focusAmount) }}</span>
          <span class="title">关注</span>
        </div>
      </div>

      <div class="price-group"><!--{{wallet.totalYanzhiValue || 0}}-->
        <router-link to="/wallet" class="wallet-remain">
          <div class="wallet-label">严值余额</div>
          <div class="wallet-value">
            <template>{{ wallet.totalRemainValue > 999 ? '999+' : yanzhiFilter(wallet.totalRemainValue) }}</template>
            <template>(¥{{
                wallet.totalRemainValue > 999 ? '99+' : moneyFilter(wallet.totalRemainValue / 10)
              }})
            </template>
          </div>
        </router-link>
        <router-link to="/wallet" class="wallet-cash-out">
          <div class="wallet-label">可提现</div>
          <div class="wallet-value">
            <template>{{ wallet.remainProfitValue > 999 ? '999+' : yanzhiFilter(wallet.remainProfitValue) }}</template>
            <template>(¥{{
                wallet.remainProfitValue > 999 ? '99+' : moneyFilter(wallet.remainProfitValue / 10)
              }})
            </template>
          </div>
        </router-link>
      </div>

      <van-tabs v-if="info" v-model="active" background="#fff" animated swipeable>
        <van-tab>
          <template #title>
            <span class="tab-title">我的上传</span>
          </template>
          <div :class="upload.records.length && 'tab-body'" v-if="upload.records.length">
            <van-pull-refresh v-model="upload.refreshing" @refresh="loadData(upload,true)">
              <van-list v-model="upload.loading" @load="loadData(upload,false)" :finished="!upload.hasMore"
                        :immediate-check="false">
                <div v-for="(item) in upload.records">
                  <plan-card :detail="item" @handle-click="clickItem"></plan-card>
                  <div style="margin-top: 16px"></div>
                </div>
                <div class="bottom-text" v-if="!upload.hasMore">
                  <div v-if="upload.records && upload.records.length">灵感到底了~</div>
                  <div v-else
                       class="no-data-box">
                    <img src="https://cdn.001ppt.cn/h5/assets/images/noData.png" width="150"
                         style="position: relative;top: -100px;">
                    <span class="no-data-text">噢！这可能有点儿空 (⊙v⊙)</span>
                  </div>
                </div>
                <div class="bottom-text" v-else-if="upload.loading">加载中</div>
                <div class="bottom-text" v-else>加载更多</div>
              </van-list>
            </van-pull-refresh>
          </div>
          <div class="no-data" v-else-if="!upload.refreshing">
            <img src="https://cdn.001ppt.cn/h5/assets/images/noData.png" width="150">
            <span>噢！这可能有点儿空 (⊙v⊙)</span>
            <span>请在电脑打开www.001ppt.com上传方案~</span>
          </div>
        </van-tab>
        <van-tab>
          <template #title>
            <span class="tab-title">我的推荐</span>
          </template>
          <div class="like-title">
            <span @click="caseActive = 1" :class="caseActive === 1 && 'active'">推荐的方案</span>
            <span @click="caseActive = 2" :class="caseActive === 2 && 'active'">推荐的合集</span>
          </div>
          <template v-if="caseActive == 1">
            <div :class="recommend.records.length && 'tab-body'" style="padding-top: 15px;"
                 v-if="recommend.records.length">
              <van-pull-refresh v-model="recommend.refreshing" @refresh="loadData(recommend,true)">
                <van-list v-model="recommend.loading" @load="loadData(recommend,false)" :finished="!recommend.hasMore"
                          :immediate-check="false">
                  <div v-for="(item) in recommend.records">
                    <plan-card :detail="item" @handle-click="clickItem"></plan-card>
                  </div>
                  <div class="bottom-text" v-if="!recommend.hasMore">
                    <div v-if="recommend.records && recommend.records.length">灵感到底了~</div>
                    <div v-else
                         class="no-data-box">
                      <img src="https://cdn.001ppt.cn/h5/assets/images/noData.png" width="150"
                           style="position: relative;top: -100px;">
                      <span class="no-data-text">噢！这可能有点儿空 (⊙v⊙)</span>
                    </div>
                  </div>
                  <div class="bottom-text" v-else-if="recommend.loading">加载中</div>
                  <div class="bottom-text" v-else>加载更多</div>
                </van-list>
              </van-pull-refresh>
            </div>
            <div class="no-data-case" v-else>
              <img src="https://cdn.001ppt.cn/h5/assets/images/noData.png" width="150">
              <span>噢！这可能有点儿空 (⊙v⊙)</span>
              <router-link to="/home" class="route btn">去推荐方案</router-link>
            </div>
          </template>
          <template v-else>
            <div :class="recommendPlanList.records.length && 'tab-body'" style="padding-top: 15px;"
                 v-if="recommendPlanList.records.length">
              <van-pull-refresh v-model="recommendPlanList.refreshing" @refresh="loadData(recommendPlanList,true)">
                <van-list v-model="recommendPlanList.loading" @load="loadData(recommendPlanList,false)"
                          :finished="!recommendPlanList.hasMore"
                          :immediate-check="false">
                  <div v-for="(item) in recommendPlanList.records">
                    <v-case :detail="item" group @handle-click="clickItem1"></v-case>
                  </div>
                  <div class="bottom-text" v-if="!recommendPlanList.hasMore">
                    <div v-if="recommendPlanList.records && recommendPlanList.records.length">灵感到底了~</div>
                    <div v-else
                         class="no-data-box">
                      <img src="https://cdn.001ppt.cn/h5/assets/images/noData.png" width="150"
                           style="position: relative;top: -100px;">
                      <span class="no-data-text">噢！这可能有点儿空 (⊙v⊙)</span>
                    </div>
                  </div>
                  <div class="bottom-text" v-else-if="recommendPlanList.loading">加载中</div>
                  <div class="bottom-text" v-else>加载更多</div>
                </van-list>
              </van-pull-refresh>
            </div>
            <div class="no-data-case" v-else>
              <img src="https://cdn.001ppt.cn/h5/assets/images/noData.png" width="150">
              <span>噢！这可能有点儿空 (⊙v⊙)</span>
              <router-link to="/home" class="route btn">去推荐方案</router-link>
            </div>
          </template>
        </van-tab>
        <van-tab>
          <template #title>
            <span class="tab-title">我的资料</span>
          </template>
          <div class="tab-body base">
            <div class="key-value">
              <span class="key">用户昵称</span>
              <span class="value">{{ userInfo.username }}</span>
            </div>
            <div class="key-value">
              <span class="key">性<span style="color: #fff">性别</span>别</span>
              <span class="value">{{ userInfo.sex }}</span>
            </div>
            <div class="key-value">
              <span class="key">所在城市</span>
              <span class="value">{{ clipCity(userInfo.city) }}</span>
            </div>
            <div class="key-value">
              <span class="key">职<span style="color: #fff">职业</span>业</span>
              <span class="value">{{ setVal(userInfo.profession) }}</span>
            </div>
            <div class="key-value">
              <span class="key">简<span style="color: #fff">简介</span>介</span>
              <span class="value" style="line-height: 22px;">{{ setVal(userInfo.introduction) }}</span>
            </div>
            <div class="key-value">
              <span class="key">个人标签</span>
              <span class="value" style="display: flex;align-items: center;flex-wrap: wrap;">
                  <template v-if="user.tags && user.tags.length>0">
                    <template v-for="(i, index) in user.tags">
                      <div style="padding: 4px 0;">
                        <span class="tag" style="width:auto;padding: 0 15px;"
                              :style="{marginRight: index<user.tags.length - 1?'10px': 0}">{{ i }}</span>
                      </div>
                    </template>
                  </template>
                  <span v-else class="value">未知</span>
              </span>
            </div>
          </div>
        </van-tab>
      </van-tabs>

      <van-tabs v-else v-model="fansActive" background="#fff" animated @click="onClick" swipeable>
        <van-tab>
          <template #title>
            <span class="tab-title">我的资料</span>
          </template>
        </van-tab>
        <van-tab>
          <template #title>
            <span class="tab-title">我的粉丝</span>
          </template>
          <div class="base" v-if="fans.length">
            <template v-for="item in fans">
              <!--<div class="message-item">
                  <div class="user-group">
                      <img class="avatar" :src="item.photoResourceUrl"/>
                      <div class="user-group-right">
                          <div>
                              <span class="user-name">{{item.username}}</span>
                          </div>
                          <div class="desc">
                              <span>{{item.city.split(',')[0]}}</span>
                              <span class="line">|</span>
                              <span>{{item.profession}}</span>
                          </div>
                      </div>
                  </div>
                  <span class="btn default" @click="focusUser(item.id)">关注</span>
              </div>-->

              <v-cell v-if="item.focusUserId" :detail="item" @handle-click="cancelFocus"
                      btn="已关注"></v-cell>
              <v-cell v-else :detail="item" @handle-click="focusUser" btn="相互关注"></v-cell>
            </template>
            <span class="more" v-if="fans.length >= 5" @click="showMore(0)">查看更多</span>
          </div>
          <div class="no-data" v-else>
            <img src="https://cdn.001ppt.cn/h5/assets/images/noData.png" width="150">
          </div>
        </van-tab>
        <van-tab>
          <template #title>
            <span class="tab-title">我的关注</span>
          </template>
          <div class="base" v-if="focus.length">
            <template v-for="item in focus">
              <v-cell :detail="item" @handle-click="cancelFocus" btn="已关注" type="disabled"></v-cell>
            </template>
            <span class="more" v-if="focus.length >= 5" @click="showMore(1)">查看更多</span>
          </div>
          <div class="no-data" v-else>
            <img src="https://cdn.001ppt.cn/h5/assets/images/noData.png" width="150">
          </div>
        </van-tab>
      </van-tabs>

    </div>


  </page>

</template>

<script>
import vCase from '@/components/case'
import avatar from '@/components/avatar'
import {mapGetters, mapMutations} from 'vuex'
import {user, memberInfo} from '@/api'
import VCell from '@/components/cell'
import {planV2Api} from "../../api/v2/planV2Api";
import {formatDate} from "../../filters/dateFilter";
import {sleep} from "../../config/util";
import {nav} from "@/config/nav";
import PlanCard from "../../components/planCard";
import {planRecommendV2Api} from "../../api/v2/planRecommendV2Api";

export default {
  components: {PlanCard, vCase, avatar, VCell},
  computed: {...mapGetters(['userInfo', 'userVip', 'wallet'])},
  data() {
    return {
      caseActive: 1,
      active: 0,
      info: true,
      fansActive: 1,
      user: {},
      focus: [],
      fans: [],
      recommend: {
        current: 0,
        pageSize: 20,
        records: [],
        refreshing: false,
        loading: false,
        hasMore: true,
        api: ({current, size}) => {
          return planRecommendV2Api.findByUserId({current, size, pageSize: size, userId: this.userInfo.id})
        }
      },
      upload: {
        current: 0,
        pageSize: 20,
        records: [],
        refreshing: false,
        loading: false,
        hasMore: true,
        async api({current, size}) {
          const res = await planV2Api.findMyPublish({current, size});
          return {data: res}
        }
      },
      recommendPlanList: {
        current: 0,
        pageSize: 20,
        records: [],
        refreshing: false,
        loading: false,
        hasMore: true,
        api: user.myRecommendList
      },
    }
  },
  created() {
    this.$store.dispatch('getMemberInfo');
    this.getUserInfo();
    this.myFocus();
    this.myFans();
    this.loadData(this.recommend, true);
    this.loadData(this.upload, true)
    this.loadData(this.recommendPlanList, true);
  },

  activated() {
    // alert(this.active)
    document.getElementsByClassName(`child-page`)[0].scrollTop = this.$store.state.scroll;
  },


  watch: {


    fansActive(val) {
      if (val === 0) {
        this.info = true;
        this.active = 2;
      }
    }

  },


  methods: {
    ...mapMutations(['SET_USERINFO']),

    // 加载数据
    async loadData(model, isRefresh) {
      let current = 1;
      let pageSize = 20;
      if (isRefresh) {
        model.refreshing = true;
      } else {
        model.loading = true;
        current = model.current + 1;
      }
      model.current = current;

      try {
        let res = await model.api({current, pageSize, size: pageSize});
        if (model !== this.recommend) {
          res = res.data;
        }
        model.current = res.current;
        model.pageSize = res.pageSize || res.size;
        model.hasMore = res.total > model.pageSize * model.current;
        // 处理records
        let records = isRefresh ? [] : model.records;
        for (const row of res.records) {
          records.push(row)
        }
        model.records = records;
      } catch (e) {
        console.error(e)
        model.current--;
      } finally {
        model.loading = false;
        await sleep(300);
        model.refreshing = false;
      }

    },


    //获取用户信息
    async getUserInfo() {
      const res = await user.info();
      res.data.tags = res.data.tags ? res.data.tags.split(',') : [];
      this.user = res.data;
      this.SET_USERINFO(res.data);
    },


    //修改封面
    changeTitle() {
      const input = document.createElement('input');
      input.type = 'file';
      input.accept = 'image/*';
      input.click();
      const self = this;
      input.onchange = async function () {

        var formData = new FormData();
        formData.append('file', input.files[0]);

        const {code, data, msg} = await user.upload(formData);

        if (code != 1) return self.$toast.error('上传失败');
        const res = await user.changeCover(data.id);
        if (res.code != 1) return self.$toast.error('更新失败');
        self.userInfo.titleResourceUrl = data.fullPath;
        self.SET_USERINFO(self.userInfo);

      }
    },

    //我的关注
    async myFocus() {
      const res = await user.myFocus({current: 1, size: 5, status: 4});
      this.focus = res.data.records;
    },
    //我的粉丝
    async myFans() {
      const res = await user.myFans({current: 1, size: 5});
      this.fans = res.data.records;
    },
    showFans(active) {
      this.info = false;
      this.fansActive = active;
    },

    onClick(index) {
      if (index === 0) {
        this.info = true;
      }
    },

    //取消关注
    async cancelFocus({id}) {
      const {code} = await user.cancelFocus(id);
      if (code != 1) return;
      this.userInfo.focusAmount -= 1;
      this.SET_USERINFO(this.userInfo);
      this.myFocus();
      this.myFans();
      this.$toast.success('取消关注成功');
    },

    //关注用户
    async focusUser({id}) {
      const {code} = await user.focus({toUserId: id, userId: this.userInfo.id});
      if (code != 1) return;
      this.userInfo.focusAmount += 1;
      this.SET_USERINFO(this.userInfo);
      this.myFocus();
      this.myFans();
      this.$toast.success('关注成功');
    },

    showMore(active) {
      this.$router.push('/fansAndFocus?active=' + active);
    },


    //去别人的主页
    otherPerson(data) {
      this.$router.push(`/otherPerson/${data.id}`);
    },


    clickItem(data) {
      this.$store.commit('SET_scroll', document.getElementsByClassName('child-page')[0].scrollTop)
      nav.goPlanDetail(data.plan.id)
    },

    clickItem1(data) {
      this.$store.commit('SET_scroll', document.getElementsByClassName('child-page')[0].scrollTop)
      this.$router.push(`/casePlanDetail/${data.id}`)
    },
  }
}
</script>

<style scoped lang="less">
.mine {
  background: @page-color;
  overflow-y: auto;
  overflow-x: hidden;
  min-height: 100vh;

  .user-title-img {
    position: relative;
    left: -1px;
    width: calc(100vw + 1px);
    height: 160px;
    object-fit: cover;
  }

  .userinfo-group {
    height: calc(100vh - 50px - 160px);

    position: relative;

    .user-card {
      position: absolute;
      width: calc(100vw - 50px);
      left: 25px;
      height: 90px;
      top: -45px;
      box-shadow: 0 4px 4px 0 rgba(21, 29, 54, 0.05);
      border-radius: @card-radius;
      background: #fff;
      z-index: 1;
      display: flex;
      align-items: center;
      padding-left: 14px;
      padding-right: 30px;
      box-sizing: border-box;

      .avatar-cell {
        width: 52px;
        height: 52px;
        position: relative;

        .avatar {
          width: 52px;
          height: 52px;
          border-radius: 100%;
        }

        .vip-img {
          position: absolute;
          bottom: 2px;
          right: 2px;
          width: 15px;
          height: 15px;
          border-radius: 100%;
        }
      }


      .usernamegroup {
        box-sizing: border-box;
        padding-left: 15px;
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .user-group {
          display: flex;
          align-items: center;

          .username {
            max-width: 150px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            font-size: 20px;
            font-weight: bold;
          }

          .level {
            width: 34px;
            height: 17px;
            margin-left: 10px;
          }
        }

      }

      &:active {
        background: @page-color;
      }
    }

    .box-group {
      background: #fff;
      height: 45px;
    }

    .user-count {
      background: #fff;
      display: flex;
      padding: 24px 0;

      > div {
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .number {
          font-size: 18px;
          font-weight: bold;
        }

        .title {
          font-size: 12px;
          color: #999;
          margin-top: 3px;
          display: flex;
          align-items: center;
        }
      }
    }

    .price-group {
      background: #fff;
      display: flex;
      align-items: center;
      padding: 0 28px 24px 28px;
      justify-content: space-between;
    }

    .tab-title {
      font-weight: bold;
    }

    .like-title {
      display: flex;
      align-items: center;
      padding: 15px 73px 0;

      span {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        color: var(--grey-color);

        &.active {
          color: var(--text-color);
        }
      }
    }

    .tab-body {
      box-sizing: border-box;
      background: var(--page-color);
      padding: 24px 28px;
    }

    .base {
      background: #fff;
      border-top: 14px solid var(--page-color);
      padding-top: 10px;

      .key-value {
        display: flex;
        align-items: center;
        min-height: 40px;

        .key {
          display: inline-block;
          text-align: justify;
          width: 60px;
          color: var(--grey-color);
          text-align-last: justify;
          margin-right: 30px;
          font-weight: bold;
          text-justify: inter-ideograph;
          opacity: .3;
        }

        .value {
          flex: 1;
          display: flex;

          .tag {
            box-sizing: border-box;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 104px;
            height: 25px;
            opacity: 0.3;
            color: rgba(80, 80, 80, 1);
            border: 1px solid #999;
            box-shadow: var(--text-color) solid 1px;
            border-radius: 13px;
            font-size: 12px;
          }
        }
      }
    }
  }

  .desc {
    display: flex;
    align-items: center;

    span {
      font-size: 12px;
      color: #999;
    }

    .line {
      margin: 0 8px;
    }
  }


  .more {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 0;
    color: var(--grey-color);
    cursor: pointer;
  }

  .no-data {
    background: var(--page-color);
    height: calc(100vh - 155px - 160px - 88px - 68px);
    display: flex;
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;

    span {
      margin-top: 10px;
      color: #999;
      font-size: 12px;
      opacity: .25;
    }

    .route {
      margin-top: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: #fff;
      width: 140px;
      height: 40px;
      color: var(--text-color);
      font-size: 14px;
      border-radius: 21px;
    }
  }

  .no-data-case {
    background: var(--page-color);
    height: calc(100vh - 155px - 160px - 88px - 68px - 34px);
    display: flex;
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;

    span {
      margin-top: 10px;
      color: #999;
      font-size: 12px;
      opacity: .25;
    }

    .route {
      margin-top: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: #fff;
      width: 140px;
      height: 40px;
      color: var(--text-color);
      font-size: 14px;
      border-radius: 21px;
    }
  }
}

.no-data-box {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative
}

.no-data-box .no-data-text {
  position: relative;
  top: -100px;
  margin-top: 10px;
  color: #999;
  font-size: 12px;
  opacity: .25;
}

.bottom-text {
  color: #999;
  text-align: center;
  padding: 16px 0;
}


.no-data-box {
  margin: 200px 0;
}

.wallet-remain {
  display: flex;
  width: 154px;
  height: 58px;
  background: #151D36;
  border-radius: 6px;
  color: #fff;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.wallet-cash-out {
  width: 154px;
  height: 58px;
  display: flex;
  border-radius: 6px;
  //background: rgba(244, 204, 148, 1);
  color: rgba(21, 29, 54, 1);
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-image: url("https://cdn.001ppt.cn/h5/static/image/mine-wallet-profit.png");
  background-size: 100% 100%;
}

.wallet-label {
  font-size: 12px;
  height: 15px;
  line-height: 15px;
}

.wallet-value {
  height: 29px;
  font-size: 22px;
  line-height: 29px;
  font-weight: bold;
}
</style>
