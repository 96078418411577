<template>
    <div class="message-item">
        <div class="user-group" @click="otherPerson(detail)">
            <avatar class="avatar" :src="detail.photoResourceUrl"></avatar>
            <div class="user-group-right">
                <div>
                    <span class="user-name" style="min-width: 100px;">{{detail.username}}</span>
                </div>
                <div class="desc">
                    <span>{{clipCity(detail.city)}}</span>
                    <span class="line">|</span>
                    <span>{{setVal(detail.profession)}}</span>
                </div>
            </div>
        </div>
        <span v-if="userInfo.id != detail.id" class="btn" :class="type" @click="click(detail)">{{btn}}</span>
    </div>
</template>

<script>
    import avatar from './avatar'
    import {user} from '@/api'
    import {mapGetters} from 'vuex'
    export default {
        computed: {...mapGetters(['userInfo'])},
        name: "cell",
        components: {avatar},
        props: {
            detail: {
                type: Object,
                default: {}
            },
            btn: {
                type: String,
                default: ''
            },
            type: {
                type: String,
                default: 'disabled'
            },
        },
        methods: {
            //取消关注
            async click(data){
                this.$emit('handle-click',data);
            },


            otherPerson(detail){
                if(this.$route.params.id &&  detail.id == this.$route.params.id)return;
                window.location.href = window.location.origin+`/otherPerson/${detail.id}`
            }
        }
    }
</script>

<style scoped lang="less">
    .message-item{
        padding: 15px 27px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: relative;
    }

    .desc {
        display: flex;
        align-items: center;

        span {
            font-size: 12px;
            color: #999;
        }

        .line {
            margin: 0 8px;
        }
    }

    .message-item .user-group{
        display: flex;
        align-items: center;
    }

    .user-name{
        font-weight: bold;
        margin-right: 15px;
    }

    .time{
        font-size: 12px;
        opacity: .3;
    }

    .action{
        display: flex;
        width: 180px;
        overflow:hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }

    .message-item .avatar{
        width: 59px;
        height: 59px;
        border-radius: 100%;
        margin-right: 13px;
    }

    .message-item .btn{
        display: flex;
        width: 70px;
        height: 25px;
        align-items: center;
        justify-content: center;
        color: #fff;
        border-radius: 12px;
        font-size: 12px;
    }
    .message-item .btn.fans{
        background-color: #ffcb64;
    }
    .message-item .desc{
        margin-top: 5px;
    }

    .message-item .btn.default{
        background-color: var(--text-color);
    }
    .message-item .btn.disabled{
        background-color: var(--text-color);
        opacity: .1;
    }
</style>
